<template>
  <section id="cv">
      <div class="big-header">
        <Sectionheader @click="toggleExpansion()" :title="selectedLanguageEN ? cvTitleEn : cvTitleDe" :isExpanded="isExpanded" :isH3="false" />
      </div>
      <div v-if="sharedState.currentSettingsForProfile.positioncv">
        <Hardskills v-if="hardSkillsIsFirst" :class="{expand: isExpanded, shrinked: !isExpanded}" />
        <Softskills v-if="softSkillsIsFirst" :class="{expand: isExpanded, shrinked: !isExpanded}" />
        <Professionalexperience v-if="timelineIsFirst" :class="{'expand-timeline': isExpanded, 'shrinked-timeline': !isExpanded}" />
        <Education v-if="timelineIsFirst" :class="{'expand-timeline': isExpanded, 'shrinked-timeline': !isExpanded}" />
        <Wholetimeline v-if="timelineIsFirst" class="display-at-md" />

        <Hardskills v-if="hardSkillsIsSecond" :class="{expand: isExpanded, shrinked: !isExpanded}" />
        <Softskills v-if="softSkillsIsSecond" :class="{expand: isExpanded, shrinked: !isExpanded}" />
        <Professionalexperience v-if="timelineIsSecond" :class="{'expand-timeline': isExpanded, 'shrinked-timeline': !isExpanded}" />
        <Education v-if="timelineIsSecond" :class="{'expand-timeline': isExpanded, 'shrinked-timeline': !isExpanded}" />
        <Wholetimeline v-if="timelineIsSecond" class="display-at-md" />

        <Hardskills v-if="hardSkillsIsLast" :class="{expand: isExpanded, shrinked: !isExpanded}" />
        <Softskills v-if="softSkillsIsLast" :class="{expand: isExpanded, shrinked: !isExpanded}" />
        <Professionalexperience v-if="timelineIsLast" :class="{'expand-timeline': isExpanded, 'shrinked-timeline': !isExpanded}" />
        <Education v-if="timelineIsLast" :class="{'expand-timeline': isExpanded, 'shrinked-timeline': !isExpanded}" />
        <Wholetimeline v-if="timelineIsLast" class="display-at-md" />
      </div>
      <div v-else>
        <Hardskills :class="{expand: isExpanded, shrinked: !isExpanded}" />
        <Softskills :class="{expand: isExpanded, shrinked: !isExpanded}" />
        <Professionalexperience :class="{'expand-timeline': isExpanded, 'shrinked-timeline': !isExpanded}" />
        <Education :class="{'expand-timeline': isExpanded, 'shrinked-timeline': !isExpanded}" />
        <Wholetimeline class="display-at-md" />
      </div>
  </section>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Sectionheader from '@/components/elements/Sectionheader.vue';
import Hardskills from '@/components/semantic/Hardskills.vue';
import Softskills from '@/components/semantic/Softskills.vue';
import Professionalexperience from '@/components/semantic/Professionalexperience.vue';
import Education from '@/components/semantic/Education.vue';
import Wholetimeline from '@/components/semantic/Wholetimeline.vue';
import store from '@/store';
import * as settings from '@/utils/settings';
import { Language } from '@/interfaces/Language';

@Options({
  data () {
    return {
      isExpanded: false,
      sharedState: store.state,
    }
  },
  components: {
      Sectionheader,
      Hardskills,
      Softskills,
      Professionalexperience,
      Education,
      Wholetimeline
  },
  computed: {
    positionHardSkills() { return store.state.currentSettingsForProfile.positionhardskills; },
    positionSoftSkills() { return store.state.currentSettingsForProfile.positionsoftskills; },
    positionTimelineElements() { return store.state.currentSettingsForProfile.positiontimeline; },
    hardSkillsIsFirst() { return settings.elementIsFirst(this.positionHardSkills); },
    hardSkillsIsSecond() { return settings.elementIsSecond(this.positionHardSkills); },
    hardSkillsIsLast() { return settings.elementIsLast(this.positionHardSkills); },
    softSkillsIsFirst() { return settings.elementIsFirst(this.positionSoftSkills); },
    softSkillsIsSecond() { return settings.elementIsSecond(this.positionSoftSkills); },
    softSkillsIsLast() { return settings.elementIsLast(this.positionSoftSkills); },
    timelineIsFirst() { return settings.elementIsFirst(this.positionTimelineElements); },
    timelineIsSecond() { return settings.elementIsSecond(this.positionTimelineElements); },
    timelineIsLast() { return settings.elementIsLast(this.positionTimelineElements); },
    selectedLanguageEN() { return store.state.selectedLanguage === Language.EN;  },
    cvTitleEn() { return store.state.currentSettingsForProfile.cvtitle?.en; },
    cvTitleDe() { return store.state.currentSettingsForProfile.cvtitle?.de; },
  },
  methods: {
  }
})
export default class Cv extends Vue {
    toggleExpansion() {
      this.isExpanded = !this.isExpanded;
    }
}
</script>

<style lang="scss">
@import '@/main.scss';

  #cv {
    display: flex;
    flex-direction: column;
  }

  .timeline {
    border-left: 1px solid var(--text-color);
    margin-left: 5%;
    padding-left: 3%;
    position: relative;
  }

  .expand-timeline {
    display: unset;
  }

  .shrinked-timeline {
    display: none;
  }
  
  @media screen and (min-width: $break-md) {
    .expand-timeline {
      display: none;
    }

    .shrinked-timeline {
      display: none;
    }

    .timeline {

    }
  }
</style>