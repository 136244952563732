<template>
    <div class="skill-info">
      <div>
        <h3>
            {{title}}
        </h3>
        <i class="fas fa-times close-icon" @click="closeInfo"></i>
      </div>
        <img class="skill-icon" :src="getImageSource" />
        <h4 v-if="selectedLanguageEN">{{hardskillsarticlesEN}}</h4>
        <h4 v-else>{{hardskillsarticlesDE}}</h4>
        <router-link 
          to="/about"
          v-scroll-to="{ el: '#projects', offset: -100, duration: 2000 }"
          >
          <ul v-if="relatedPosts.length >= 1 && selectedModeLIGHT">
              <li v-for="(post, i) in relatedPosts.slice(0,2)" v-bind:key="post.id" :class="{darkblue: (i % 2 == 0), lightblue: (i % 2 == 1)}" @click="goToArticle(post.id)">
                <h3 v-if="selectedLanguageEN">{{post.title}}</h3>
                <h3 v-else>{{post.title_DE}}</h3>
              </li>
          </ul>
          <ul v-if="relatedPosts.length >= 1 && !selectedModeLIGHT">
              <li v-for="(post, i) in relatedPosts.slice(0,2)" v-bind:key="post.id" :class="{lightblue: (i % 2 == 0), lightcreme: (i % 2 == 1)}" @click="goToArticle(post.id)">
                <h3 v-if="selectedLanguageEN">{{post.title}}</h3>
                <h3 v-else>{{post.title_DE}}</h3>
              </li>
          </ul>
        </router-link>
        <div v-if="relatedPosts.length < 1">
          <p v-if="selectedLanguageEN">{{hardskillsnoprojectfoundEN}}</p>
          <p v-else>{{hardskillsnoprojectfoundDE}}</p>
        </div>
        
    </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import { Language } from '@/interfaces/Language';
import { Mode } from '@/interfaces/Mode';
import * as http from '@/http';
import store from '@/store';

@Options({
  props: {
    title: '',
    subtitle: '',
    imgSrc: '',
    skillid: '',
  },
  data () {
      return {
          relatedPosts: {},
      }
  },
  computed: {
      getImageSource() {
          if(!this.imgSrc.includes("https://"))
            return require('/src/assets/' + this.imgSrc);
          else
            return this.imgSrc;
      },
      selectedLanguageEN() { return store.state.selectedLanguage === Language.EN;  },
      selectedModeLIGHT() { return store.state.selectedMode === Mode.LIGHT; },
      hardskillsarticlesEN() { return store.state.currentSettingsForProfile.hardskillsarticles?.en; },
      hardskillsarticlesDE() { return store.state.currentSettingsForProfile.hardskillsarticles?.de; },
      hardskillsnoprojectfoundEN() { return store.state.currentSettingsForProfile.hardskillsnoprojectfound?.en; },
      hardskillsnoprojectfoundDE() { return store.state.currentSettingsForProfile.hardskillsnoprojectfound?.de; },
  },
  mounted: function() {
      this.getRelatedPosts();
  },
  methods: {
      async getRelatedPosts() {
          this.relatedPosts = await http.getPostsBySkillId(this.skillid);
      },
      closeInfo() {
        store.resetShowInfoOfSkill();
      },
      goToArticle(id) {
        console.log("Go to Article with Id: ", id);
      }
  }
})
export default class Skillinfo extends Vue {
}
</script>

<style lang="scss" scoped>
@import '@/main.scss';

  .skill-info {
    position: absolute;
    background-color: var(--background-light);
    top: 40px;
    left: -42.5vw;
    width: 350px;
    height: 400px;
    padding-top: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  .close-icon {
    display: unset;
    font-size: 30px;
    position: absolute;
    top: 5%;
    right: 5%;
    color: var(--highlight-color);
  }

  .skill-icon {
    max-height: 100px;
    height: 100px;
    margin: 0;
  }

  ul {
    list-style-type: none;
    margin-left: -11%;
  }

  li {
    background-color: var(--background-dark);
    padding: 5%;
    margin: 0;
    color: var(--text-color-light);
  }

  .lightcreme {
    background-color: var(--background-dark);
  }

  .darkblue {
    background-color: var(--background-dark);
  }

  .lightblue {
    background-color: var(--background-middle);
    color: var(--text-color);
  }

  .proj-thumbnail {
    max-height: 50px;
    width: auto;
  }

  p {
    padding-bottom: 10%;
  }

  @media screen and (min-width: $break-sm) {
    .skill-info {
      top: 40px;
      left: -25vw;
      width: 400px;
    }
  }

  @media screen and (min-width: $break-md) {
    .skill-info {
      position: relative;
      margin: 0 10px 30px 10px;
      min-width: 350px;
      max-width: 350px;
      min-height: 400px;
      top: unset;
      left: unset;
      right: unset;
      flex: 1 1 33%;
    }

    .close-icon {
      display: none;
    }

    .lightcreme {
      background-color: var(--highlight-color);
      color: var(--text-color);
    }

    ul {
      margin-left: unset;
      padding-left: 0;
    }
  }

</style>