<template>
  <div id="zerio">
    <Techblogheader />
    <main id="zerio-body-wrapper">
      <template v-for="post in posts" v-bind:key="post.postid">
        <router-link class="article-link"  to="/article" @click="setArticleId(post.id)">
          <Postoverview
            :thumbnailSrc="post.thumbnail.url"
            :title="post.title"
            :title_DE="post.title_DE"
            :teaser="post.teaser"
            :teaser_DE="post.teaser_DE"
            :colorStyle="getColorNumber(post.id)"
            :creatorname="post.author.name"
            :publishedAt="post.published_at"
          />
        </router-link>
      </template>
    </main>
    <Footer :isPortfolio="false" />
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Techblogheader from '@/components/semantic/Techblogheader.vue';
import Postoverview from '@/components/elements/Postoverview.vue';
import Footer from '@/components/semantic/Footer.vue';
import * as http from '@/http'
import store from '@/store';

@Options({
  components: {
    Techblogheader,
    Postoverview,
    Footer
  },
  data () {
    return {
      posts: {},
    }
  },
  created: function() {
    this.loadAllPosts();
  },
  methods: {
    async loadAllPosts() {
      this.posts = await http.getAllPosts();
      console.log("Posts: ",this.posts);
    },
    getColorNumber(id) {
      return (id % 2) + 1;
    }
  }
})
export default class Zerio extends Vue {

  setArticleId(articleId) {
    store.setArticleId(articleId);
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

  #zerio {
    min-height: 100vh;
    background: var(--background-dark);
  }

  #zerio-body-wrapper {
    padding-top: 25%;
    padding-bottom: 25%;
  }

  .article-link {
    text-decoration: none;
  }

  @media screen and (min-width: $break-md) {
    #zerio-body-wrapper {
      padding-top: 110px;
      padding-bottom: 5%;

      display: inline-grid;
      grid-template-columns: auto auto auto;
    }
  }
</style>