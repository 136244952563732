<template>
  <article :class="{'post-container': true, darkblue: (colorStyle % 3 == 0), lightblue: (colorStyle % 3 == 1), lightcreme: (colorStyle % 3 == 2)}">
    <div class="thumbnail-wrapper">
      <img class="thumbnail" :src="thumbnailSrc" />
    </div>
    <h3 v-if="selectedLanguageEN" :class="{'darkblue-text': (colorStyle % 3 == 0), 'lightblue-text': (colorStyle % 3 == 1), 'lightcreme-text': (colorStyle % 3 == 2)}">{{title}}</h3>
    <h3 v-else :class="{'darkblue-text': (colorStyle % 3 == 0), 'lightblue-text': (colorStyle % 3 == 1), 'lightcreme-text': (colorStyle % 3 == 2)}">{{title_DE}}</h3>
    <p v-if="selectedLanguageEN" :class="{'text-left': true, 'darkblue-text': (colorStyle % 3 == 0), 'lightblue-text': (colorStyle % 3 == 1), 'lightcreme-text': (colorStyle % 3 == 2)}">{{teaser}}</p>
    <p v-else :class="{'text-left': true, 'darkblue-text': (colorStyle % 3 == 0), 'lightblue-text': (colorStyle % 3 == 1), 'lightcreme-text': (colorStyle % 3 == 2)}">{{teaser_DE}}</p>
    <div class="author-and-release">
      <div class="author-info">
        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
        <p :class="{'darkblue-text': (colorStyle % 3 == 0), 'lightblue-text': (colorStyle % 3 == 1), 'lightcreme-text': (colorStyle % 3 == 2)}">{{ creatorname }}</p>
      </div>
      <div class="release-info">
        <p :class="{'darkblue-text': (colorStyle % 3 == 0), 'lightblue-text': (colorStyle % 3 == 1), 'lightcreme-text': (colorStyle % 3 == 2)}">{{ getReleaseDate }}</p>
        <i class="fa fa-clock-o" aria-hidden="true"></i>
      </div>
    </div>
  </article>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import { Language } from '@/interfaces/Language';

@Options({
    props: {
        thumbnailSrc: '',
        title: '',
        title_DE: '',
        teaser: '',
        teaser_DE: '',
        colorStyle: '',
        creatorname: '',
        publishedAt: '',
    },
    computed: {
      selectedLanguageEN() { return store.state.selectedLanguage === Language.EN;  },
      getReleaseDate() { 
        const publishDate = new Date(this.publishedAt);
        return publishDate.getUTCDate() + "." + publishDate.getMonth() + "." + publishDate.getFullYear();
      }
    }
})

export default class Postoverview extends Vue {}
</script>

<style lang="scss" scoped>
@import '@/main.scss';

  .post-container {
    margin: 0;
    padding: 5%;
    padding-bottom: 40px;
  }

  .thumbnail {
    max-width: 90vw;
  }

  h3 {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .text-left {
    text-align: left;
    margin: 0 3% 0 5%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .darkblue {
    background: var(--background-dark);
    color: var(--text-color-light);
  }

  .darkblue-text {
    color: var(--text-color-light);
  }

  .lightblue {
    background: var(--background-middle);
    color: var(--text-color);
  }

  .lightblue-text {
    color: var(--text-color);
  }

  .lightcreme {
    background: var(--background-light);
    color: var(--text-color);
  }

  .lightcreme-text {
    color: var(--text-color);
  }

  .author-and-release {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .author-info {
    display: flex;
    align-items: center;

    /deep/ p {
      margin: 0 0 0 5px;
    }
  }

  .release-info {
    display: flex;
    align-items: center;

    /deep/ p {
      margin: 0 5px 0 0;
    }
  }

  @media screen and (min-width: $break-md) {
    .post-container {
      max-width: 350px;
      padding: 20px 20px 40px 20px;
      flex: 1 0 33%;
    }

    .thumbnail {
      max-width: 300px;
    }

    h3 {
      min-height: 60px;
    }

    .thumbnail-wrapper {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>