<template>
    <div>
        <div :class="{'section-header': true, 'section-header-light': selectedModeLIGHT, 'section-header-dark': !selectedModeLIGHT,'section-header-h2': !isH3, 'section-header-h3': isH3, 'flex-wrapper': isWithArrow}">
            <i v-if="!isExpanded && (!isH3 && !isWithArrow)" class="fas fa-chevron-right expandable"></i>
            <i v-if="isExpanded && (!isH3 && !isWithArrow)" class="fas fa-chevron-down expanded"></i>
            <div v-if="!isWithArrow" class="left-section-header-arrow">
                <Arrow class="display-at-md" v-if="!isWithArrow" :left="true" :right="false" :isNotCv="isNotCv" :highlightcolor="getHighlightColor" :mediumcolor="getMediumColor" :darkcolor="getDarkColor" :lightcolor="getLightColor" />
            </div>
            <Arrow v-if="isWithArrow" :highlightcolor="getHighlightColor" :mediumcolor="getMediumColor" :darkcolor="getDarkColor" :lightcolor="getLightColor" />
            <div class="flex-wrapper-at-md">
                <h2 class="display-at-md">{{supertitle}}</h2>
                <h2 v-if="!isH3 && !isOnFocus">{{title}}</h2>
                <h2 v-if="!isH3 && isOnFocus"><mark>{{title}}</mark></h2>
                <h3 v-if="isH3">{{title}}</h3>
            </div>
            <div v-if="!isWithArrow" class="right-section-header-arrow">
                <Arrow class="display-at-md" v-if="!isWithArrow" :left="false" :right="true" :darkMode="!selectedModeLIGHT" :highlightcolor="getHighlightColor" :mediumcolor="getMediumColor" :darkcolor="getDarkColor" :lightcolor="getLightColor" />
            </div>
        </div>
        <hr id="underline" v-if="isH3" />
    </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Arrow from '@/components/elements/Arrow.vue';
import store from '@/store';
import { Mode } from '@/interfaces/Mode';

@Options({
    components: {
        Arrow,
    },
    props: {
        title: '',
        supertitle: '',
        isExpanded: false,
        isOnFocus: false,
        isH3: false,
        isWithArrow: false,
        isNotCv: false,
    },
    computed: {
        getDarkColor() { return store.state.currentSettingsForProfile.textcolor; },
        getMediumColor() { return store.state.currentSettingsForProfile.backgroundmiddle; },
        getHighlightColor() { return store.state.currentSettingsForProfile.highlightcolor; },
        getLightColor() { return store.state.currentSettingsForProfile.backgroundlight; },
        selectedModeLIGHT() { return store.state.selectedMode === Mode.LIGHT; },
    }
})
export default class Sectionheader extends Vue {}
</script>

<style lang="scss">
@import '@/main.scss';

    .section-header {
        display: flex;
        margin-top: 2.5%;
        margin-left: 7.5%;
        text-align: center;
        color: var(--text-color);
    }
    
    .section-header-light {
        color: var(--text-color); 
    }

    .section-header-dark {
        color: var(--text-color);
    }

    #underline {
        margin-left: 5%;
        margin-right: 5%;
        margin-top: -1%;
        border: 1px solid var(--text-color);
    }

    .expandable {
        color: var(--highlight-color);
        font-size: 36px;
        margin-right: 5%;
    }

    .expanded {
        color: var(--highlight-color);
        font-size: 36px;
        margin-right: 2.5%;
    }

    mark {
        background: var(--highlight-color);
    }

    .flex-wrapper {
        display: flex;
    }

    .flex-wrapper-at-md {
        display: unset;
    }

    .flex-wrapper > svg {
        margin-left: -100px;
    }

    @media screen and (min-width: $break-sm) {
        .section-header-h3 {
            margin-left: 0;
            justify-content: space-evenly;
        }
    }

    @media screen and (min-width: $break-md) {
        .section-header {
            margin-left: 0;
            justify-content: space-evenly;
            overflow: hidden;
        }

        .section-header-dark {
            color: var(--text-color-light);
        }

        .expandable {
            display: none;
        }

        .expanded {
            display: none;
        }

        #underline {
            display: none;
        }

        .flex-wrapper-at-md {
            display: flex;
            flex-direction: column;
            min-width: 300px;
        }
    }
</style>