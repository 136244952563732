<template>
  <div id="education">
      <Sectionheader :isH3="true" :title="selectedLanguageEN ? titleEN : titleDE" />
      <div class="timeline">
        <div v-for="education in sharedState.currentProfile.educations" 
              v-bind:key="education.id"
        >
          <Timelineentry
              :entryId="education.id"
              :fromDate="education.fromdate"
              :toDate="education.todate"
              :institution="education.institute"
              :description="education.description"
              :description_DE="education.description_DE"
          />
          <Additionalinfo 
            v-if="getSelectedEntry === education.id || screenWidthHigherThan950"
            :textDE="education.detail_DE"
            :textEN="education.detail_EN"
            :imageSrc="education.thumbnail.url"
            :isEducation="education.education"
          />
        </div>
      </div>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Sectionheader from '@/components/elements/Sectionheader.vue';
import Timelineentry from '@/components/elements/Timelineentry.vue';
import Additionalinfo from '@/components/elements/Additionalinfo.vue';
import { Language } from '@/interfaces/Language';
import store from '@/store';

@Options({
    components: {
      Sectionheader,
      Timelineentry,
      Additionalinfo
    },
    data() {
        return {
            sharedState: store.state,
        }
    },
    computed: {
      selectedLanguageEN() { return store.state.selectedLanguage === Language.EN;  },
      titleEN() { return store.state.currentSettingsForProfile.educationtitle?.en; },
      titleDE() { return store.state.currentSettingsForProfile.educationtitle?.de; },
      getSelectedEntry(){
        return store.state.showInfoOfEntry;
      },
      screenWidthHigherThan950() {
        return screen.width > 950;
      },
    }
})
export default class Education extends Vue {}
</script>

<style>
</style>