<template>
  <div id="professional-experience">
    <Sectionheader :isH3="true" :title="selectedLanguageEN ? titleEN : titleDE" />
    <div class="timeline">
        <div v-for="profession in sharedState.currentProfile.professions" 
            v-bind:key="profession.id"
        >
          <Timelineentry 
              :entryId="profession.id"
              :fromDate="profession.fromdate"
              :toDate="profession.todate"
              :institution="profession.institute"
              :description="profession.description"
              :description_DE="profession.description_DE"
          />
          <Additionalinfo 
            v-if="getSelectedEntry === profession.id || screenWidthHigherThan950"
            :textDE="profession.detail_DE"
            :textEN="profession.detail_EN"
            :imageSrc="profession.thumbnail.url"
            :isEducation="profession.education"
          />
        </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Sectionheader from '@/components/elements/Sectionheader.vue';
import Timelineentry from '@/components/elements/Timelineentry.vue';
import Additionalinfo from '@/components/elements/Additionalinfo.vue';
import { Language } from '@/interfaces/Language';
import store from '@/store';

@Options({
  data () {
      return {
          sharedState: store.state,
      }
  },
  components: {
      Sectionheader,
      Timelineentry,
      Additionalinfo
  },
  computed: {
    selectedLanguageEN() { return store.state.selectedLanguage === Language.EN;  },
    titleEN() { return store.state.currentSettingsForProfile.professionstitle?.en; },
    titleDE() { return store.state.currentSettingsForProfile.professionstitle?.de; },
    getSelectedEntry(){
      return store.state.showInfoOfEntry;
    },
    screenWidthHigherThan950() {
      return screen.width > 950;
    },
  }
})
export default class Professionalexperience extends Vue {}
</script>

<style>
</style>