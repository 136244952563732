<template>
  <div>
        <Sectionheader :title="selectedLanguageEN ? titleEN : titleDE" :supertitle="selectedLanguageEN ? cvTitleEN : cvTitleDE" :isH3="true" />
        <div class="interest-box-wrapper">
            <Interest v-for="softskill in getProfileData.softskills" v-bind:key="softskill.softskillid" 
                :title="softskill.title"
                :title_DE="softskill.title_DE" 
                :subtitle="softskill.description" 
                :subtitle_DE="softskill.description_DE"
                :imgSrc="softskill.icon.url"
                :sideimageSrc="softskill.sideimage.url" 
                :isSoftSkill="true"
                :softskillid="softskill.id"
            />
        </div>    
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Sectionheader from '@/components/elements/Sectionheader.vue';
import Interest from '@/components/elements/Interest.vue';
import { Language } from '@/interfaces/Language';
import store from '@/store';

@Options({
  components: {
    Sectionheader,
    Interest,
  },
  data () {
    return {
      sharedState: store.state,
    }
  },
  computed: {
    getProfileData(){
      return this.sharedState.currentProfile;
    },
    selectedLanguageEN() { return store.state.selectedLanguage === Language.EN;  },
    titleEN() { return store.state.currentSettingsForProfile.softskillstitle?.en; },
    titleDE() { return store.state.currentSettingsForProfile.softskillstitle?.de; },
    cvTitleEN() { return store.state.currentSettingsForProfile.cvtitle?.en; },
    cvTitleDE() { return store.state.currentSettingsForProfile.cvtitle?.de; },
  }
})
export default class Softskills extends Vue {}
</script>

<style lang="scss" scoped>
@import '@/main.scss';

</style>