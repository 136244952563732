<template>
  <div>
      <img class="skill-icon" @click="toggleInfo()" :src="getImageSource" />
  </div>
</template>

<script>
import store from '@/store';
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
      title: '',
      subtitle: '',
      imgSrc: '',
      skillId: ''
  },
  data () {
    return {
      showInfo: false,
      sharedState: store.state
    }
  },
  computed: {
      getImageSource() {
          if(!this.imgSrc.includes("https://"))
            return require('/src/assets/' + this.imgSrc);
          else
            return this.imgSrc;
      }
  },
  methods: {
    toggleInfo() {
      store.setShowInfoOfSkill(this.skillId);
    }
  }
})
export default class Skill extends Vue {

}
</script>

<style lang="scss" scoped>
@import '@/main.scss';

  .skill-icon {
    max-width: 100px;
    max-height: 100px;
    margin-bottom: 30%;
  }

  @media screen and (min-width: $break-md) {
    .skill-icon {
      display: none;
    }
  }
</style>