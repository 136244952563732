<template>
  <section id="contact">
      <Sectionheader @click="toggleExpansion()" :title="selectedLanguageEN ? titleEN : titleDE" :isExpanded="isExpanded" :isOnFocus="isOnFocus" :isH3="false" :isNotCv="true" />
      <div :class="{'expand': isExpanded, 'shrinked': !isExpanded}">
        <div class="interest-box-wrapper">
          <Interest v-for="contact in getProfileData.contacts" v-bind:key="contact.contactid"
            :title="contact.address"
            :title_DE="contact.address" 
            :subtitle="contact.description" 
            :subtitle_DE="contact.description_DE"
            :imgSrc="contact.icon.url" 
            :link="contact.link"
          />
        </div>
      </div>
  </section>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Sectionheader from '@/components/elements/Sectionheader.vue';
import Interest from '@/components/elements/Interest.vue';
import store from '@/store';
import { Language } from '@/interfaces/Language';

@Options({
  data () {
    return {
      isExpanded: false,
      sharedState: store.state,
    }
  },
  components: {
      Sectionheader,
      Interest
  },
  computed: {
    getProfileData(){
      return this.sharedState.currentProfile;
    },
    selectedLanguageEN() { return store.state.selectedLanguage === Language.EN;  },
    titleEN() { return store.state.currentSettingsForProfile.contactstitle?.en; },
    titleDE() { return store.state.currentSettingsForProfile.contactstitle?.de; }
  },
  methods: {
  },
  props: {
    isOnFocus: false,
  }
})
export default class Contact extends Vue {
    toggleExpansion() {
      this.isExpanded = !this.isExpanded;
    }
}
</script>

<style lang="scss" scoped>
  .interest-box-wrapper {
    margin-top: 20px;
  }
</style>