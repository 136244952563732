import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "body-wrapper" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PortfolioHeader = _resolveComponent("PortfolioHeader")!
  const _component_Overview = _resolveComponent("Overview")!
  const _component_Cv = _resolveComponent("Cv")!
  const _component_Projects = _resolveComponent("Projects")!
  const _component_Contact = _resolveComponent("Contact")!
  const _component_Imprint = _resolveComponent("Imprint")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createBlock("div", {
    class: {'about': _ctx.selectedModeLIGHT, 'about-dark': !_ctx.selectedModeLIGHT },
    ref: "about"
  }, [
    _createVNode(_component_PortfolioHeader),
    _createVNode("main", _hoisted_1, [
      _createVNode(_component_Overview),
      (_ctx.sharedState.currentSettingsForProfile.positioncv)
        ? (_openBlock(), _createBlock("div", _hoisted_2, [
            (_ctx.cvIsFirst)
              ? (_openBlock(), _createBlock(_component_Cv, {
                  key: 0,
                  ref: "cv"
                }, null, 512))
              : _createCommentVNode("", true),
            (_ctx.projectsIsFirst)
              ? (_openBlock(), _createBlock(_component_Projects, {
                  key: 1,
                  ref: "projects"
                }, null, 512))
              : _createCommentVNode("", true),
            (_ctx.contactIsFirst)
              ? (_openBlock(), _createBlock(_component_Contact, {
                  key: 2,
                  ref: "contact",
                  isOnFocus: _ctx.sharedState.focusOnContact
                }, null, 8, ["isOnFocus"]))
              : _createCommentVNode("", true),
            (_ctx.imprintIsFirst)
              ? (_openBlock(), _createBlock(_component_Imprint, {
                  key: 3,
                  ref: "imprint",
                  isOnFocus: _ctx.sharedState.focusOnImprint
                }, null, 8, ["isOnFocus"]))
              : _createCommentVNode("", true),
            (_ctx.cvIsSecond)
              ? (_openBlock(), _createBlock(_component_Cv, {
                  key: 4,
                  ref: "cv"
                }, null, 512))
              : _createCommentVNode("", true),
            (_ctx.projectsIsSecond)
              ? (_openBlock(), _createBlock(_component_Projects, {
                  key: 5,
                  ref: "projects"
                }, null, 512))
              : _createCommentVNode("", true),
            (_ctx.contactIsSecond)
              ? (_openBlock(), _createBlock(_component_Contact, {
                  key: 6,
                  ref: "contact",
                  isOnFocus: _ctx.sharedState.focusOnContact
                }, null, 8, ["isOnFocus"]))
              : _createCommentVNode("", true),
            (_ctx.imprintIsSecond)
              ? (_openBlock(), _createBlock(_component_Imprint, {
                  key: 7,
                  ref: "imprint",
                  isOnFocus: _ctx.sharedState.focusOnImprint
                }, null, 8, ["isOnFocus"]))
              : _createCommentVNode("", true),
            (_ctx.cvIsThird)
              ? (_openBlock(), _createBlock(_component_Cv, {
                  key: 8,
                  ref: "cv"
                }, null, 512))
              : _createCommentVNode("", true),
            (_ctx.projectsIsThird)
              ? (_openBlock(), _createBlock(_component_Projects, {
                  key: 9,
                  ref: "projects"
                }, null, 512))
              : _createCommentVNode("", true),
            (_ctx.contactIsThird)
              ? (_openBlock(), _createBlock(_component_Contact, {
                  key: 10,
                  ref: "contact",
                  isOnFocus: _ctx.sharedState.focusOnContact
                }, null, 8, ["isOnFocus"]))
              : _createCommentVNode("", true),
            (_ctx.imprintIsThird)
              ? (_openBlock(), _createBlock(_component_Imprint, {
                  key: 11,
                  ref: "imprint",
                  isOnFocus: _ctx.sharedState.focusOnImprint
                }, null, 8, ["isOnFocus"]))
              : _createCommentVNode("", true),
            (_ctx.cvIsLast)
              ? (_openBlock(), _createBlock(_component_Cv, {
                  key: 12,
                  ref: "cv"
                }, null, 512))
              : _createCommentVNode("", true),
            (_ctx.projectsIsLast)
              ? (_openBlock(), _createBlock(_component_Projects, {
                  key: 13,
                  ref: "projects"
                }, null, 512))
              : _createCommentVNode("", true),
            (_ctx.contactIsLast)
              ? (_openBlock(), _createBlock(_component_Contact, {
                  key: 14,
                  ref: "contact",
                  isOnFocus: _ctx.sharedState.focusOnContact
                }, null, 8, ["isOnFocus"]))
              : _createCommentVNode("", true),
            (_ctx.imprintIsLast)
              ? (_openBlock(), _createBlock(_component_Imprint, {
                  key: 15,
                  ref: "imprint",
                  isOnFocus: _ctx.sharedState.focusOnImprint
                }, null, 8, ["isOnFocus"]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createBlock("div", _hoisted_3, [
            _createVNode(_component_Cv, { ref: "cv" }, null, 512),
            _createVNode(_component_Projects, { ref: "projects" }, null, 512),
            _createVNode(_component_Contact, {
              ref: "contact",
              isOnFocus: _ctx.sharedState.focusOnContact
            }, null, 8, ["isOnFocus"]),
            _createVNode(_component_Imprint, {
              ref: "imprint",
              isOnFocus: _ctx.sharedState.focusOnImprint
            }, null, 8, ["isOnFocus"])
          ]))
    ]),
    _createVNode(_component_Footer, { isPortfolio: true })
  ], 2))
}