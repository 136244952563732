
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import { Language } from '@/interfaces/Language';

@Options({
    props: {
        textEN: '',
        textDE: '',
        imageSrc: '',
        isEducation: false,
        isActive: false,
    },
    computed: {
        selectedLanguageEN() { return store.state.selectedLanguage === Language.EN;  },
        isEntryEducation() { return this.isEducation; },
    },
    methods: {}
})

export default class Additionalinfo extends Vue {
    closeInfo() {
        store.resetShowInfoOfEntry();
    }
}
