
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import { Language } from '@/interfaces/Language';
import { Mode } from '@/interfaces/Mode';

@Options({
  props: {
      title: '',
      title_DE: '',
      subtitle: '',
      subtitle_DE: '',
      imgSrc: '',
      link: '',
      sideimageSrc: '',
      isSoftSkill: false,
      softskillid: 0,
  },
  computed: {
      getImageSource() {
          if(!this.imgSrc.includes("https://"))
            return require('/src/assets/' + this.imgSrc);
          else
            return this.imgSrc;
      },
      getSideImageSource() {
        if(!this.sideimageSrc.includes("https://"))
            return require('/src/assets/' + this.sideimageSrc);
        else
            return this.sideimageSrc;
      },
      isIdEven() {
        console.log(this.softskillid);
        return this.softskillid % 2 == 0;
      },
      selectedLanguageEN() { return store.state.selectedLanguage === Language.EN;  },
      selectedModeLIGHT() { return store.state.selectedMode === Mode.LIGHT; },
  }
})
export default class Interest extends Vue {}
