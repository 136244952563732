<template>
  <section id="image-interest-wrapper">
    <div id="page-link-wrapper">
      <router-link :class="{'page-link': true, 'page-link-light': selectedModeLIGHT, 'page-link-dark': !selectedModeLIGHT }" to="/about" v-scroll-to="{ el: '#cv', offset: -120 }">
        <Sectionheader class="page-link-section-header" :title="selectedLanguageEN ? cvTitleEN : cvTitleDE" :isWithArrow="true"/>
      </router-link>
      <router-link :class="{'page-link': true, 'page-link-light': selectedModeLIGHT, 'page-link-dark': !selectedModeLIGHT }" to="/about" v-scroll-to="{ el: '#projects', offset: -120 }">
        <Sectionheader class="page-link-section-header" :title="selectedLanguageEN ? projectsTitleEN : projectsTitleDE" :isWithArrow="true"/>
      </router-link>
    </div>
    <img :src="getPictureUrl" :class="{'round-img': true, 'round-img-light': selectedModeLIGHT, 'round-img-dark': !selectedModeLIGHT }"/>
    <div :class="{'interest-box-wrapper': true, 'ibw-light': selectedModeLIGHT, 'ibw-dark': !selectedModeLIGHT }">
      <Interest v-for="interest in getProfileData.interests" v-bind:key="interest.insterestid"
        :title="interest.title"
        :title_DE="interest.title_DE" 
        :subtitle="interest.description"
        :subtitle_DE="interest.description_DE" 
        :imgSrc="interest.icon.url" 
      />
    </div>
  </section>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Interest from '@/components/elements/Interest.vue';
import Sectionheader from '@/components/elements/Sectionheader.vue';
import { Language } from '@/interfaces/Language';
import { Mode } from '@/interfaces/Mode';
import store from '@/store';

@Options({
  components: {
    Interest,
    Sectionheader
  },
  data () {
    return {
      sharedState: store.state,
      pictureUrl: '@/assets/mario.jpg'
    }
  },
  computed: {
    getProfileData(){
      return this.sharedState.currentProfile;
    },
    getPictureUrl(){
      if(this.sharedState.currentProfile.picture)
        return this.sharedState.currentProfile.picture.url;
      else
        return this.pictureUrl;
    },
    selectedLanguageEN() { return store.state.selectedLanguage === Language.EN;  },
    selectedModeLIGHT() { return store.state.selectedMode === Mode.LIGHT; },
    projectsTitleEN() { return store.state.currentSettingsForProfile.projectstitle?.en; },
    projectsTitleDE() { return store.state.currentSettingsForProfile.projectstitle?.de; },
    cvTitleEN() { return store.state.currentSettingsForProfile.cvtitle?.en; },
    cvTitleDE() { return store.state.currentSettingsForProfile.cvtitle?.de; },
  }
})
export default class Overview extends Vue {
}
</script>

<style lang="scss" scoped>
@import '@/main.scss';

  .round-img {
    margin-top: 5%;
    width: 70%;
    max-width: 300px;
    max-height: 300px;
    border-radius: 100%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  #page-link-wrapper {
    display: none;
  }

  .page-link {
    text-decoration: none;
  }

  @media screen and (min-width: $break-sm) {
    #image-interest-wrapper {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }

    .round-img {
      margin-top: 0;
    }
  }

  @media screen and (min-width: $break-md) {
    #image-interest-wrapper {
      justify-content: space-around;
      margin-top: 2%;
    }

    .interest-box-wrapper {
      flex: 4;
      padding: 40px 0 0 0;
      margin-top: 0;
      min-height: 360px;
      max-height: 360px;
      align-items: flex-start;
      padding-left: 2vw;
    }

    .ibw-light {
      background: var(--background-light);
      color: var(--text-color);
      filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
    }

    .ibw-dark {
      background: var(--background-dark);
      filter: drop-shadow(4px 4px 4px rgba(255, 255, 255, 0.25));
    }

    .round-img {
      flex: 2;
      margin: 0 2%;
      filter: unset;
    }

    .round-img-light {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    .round-img-dark {
      filter: drop-shadow(0px 4px 4px rgba(255, 255, 255, 0.25));
    }

    #page-link-wrapper {
      flex: 4;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      min-height: 400px;
      max-height: 400px;
      overflow: hidden;
      justify-content: space-between;
    }
    
    .page-link-section-header {
      margin-top: -5%;
    }

    .page-link {
      padding: 60px 5% 60px 0;
      margin: 0;
      
    }

    .page-link-light {
      background: var(--background-light);
      filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
    }

    .page-link-dark {
      background: var(--background-dark);
      filter: drop-shadow(4px 2px 4px rgba(255, 255, 255, 0.294));
    }
  }

  @media screen and (min-width: $break-lg) {
    .page-link {
    }
  }
</style>