import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: {'softskill-box': _ctx.isSoftSkill, '': !_ctx.isSoftSkill}
  }, [
    (_ctx.isSoftSkill)
      ? (_openBlock(), _createBlock("img", {
          key: 0,
          src: _ctx.getSideImageSource,
          class: "sideimage"
        }, null, 8, ["src"]))
      : _createCommentVNode("", true),
    _createVNode("div", {
      class: {'box': !_ctx.isSoftSkill, 'box-softskill': _ctx.isSoftSkill, 'box-light': _ctx.selectedModeLIGHT && !_ctx.isSoftSkill, 'box-dark': !_ctx.selectedModeLIGHT && !_ctx.isSoftSkill}
    }, [
      _createVNode("img", {
        src: _ctx.getImageSource,
        id: "icon"
      }, null, 8, ["src"]),
      _createVNode("div", {
        class: {'text-box': !_ctx.isSoftSkill, 'text-box-softskill': _ctx.isSoftSkill}
      }, [
        (_ctx.link !== '')
          ? (_openBlock(), _createBlock("a", {
              key: 0,
              href: _ctx.link,
              target: "_blank"
            }, [
              (_ctx.selectedLanguageEN)
                ? (_openBlock(), _createBlock("h3", _hoisted_1, _toDisplayString(_ctx.title), 1))
                : (_openBlock(), _createBlock("h3", _hoisted_2, _toDisplayString(_ctx.title_DE), 1)),
              (_ctx.selectedLanguageEN)
                ? (_openBlock(), _createBlock("h5", _hoisted_3, _toDisplayString(_ctx.subtitle), 1))
                : (_openBlock(), _createBlock("h5", _hoisted_4, _toDisplayString(_ctx.subtitle_DE), 1))
            ], 8, ["href"]))
          : _createCommentVNode("", true),
        (_ctx.link === '')
          ? (_openBlock(), _createBlock("div", _hoisted_5, [
              (_ctx.selectedLanguageEN)
                ? (_openBlock(), _createBlock("h3", _hoisted_6, _toDisplayString(_ctx.title), 1))
                : (_openBlock(), _createBlock("h3", _hoisted_7, _toDisplayString(_ctx.title_DE), 1)),
              (_ctx.selectedLanguageEN)
                ? (_openBlock(), _createBlock("h5", _hoisted_8, _toDisplayString(_ctx.subtitle), 1))
                : (_openBlock(), _createBlock("h5", _hoisted_9, _toDisplayString(_ctx.subtitle_DE), 1))
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ], 2)
  ], 2))
}