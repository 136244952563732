
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import { Profile } from '@/interfaces/Profile';
import { Language } from '@/interfaces/Language';
import { Mode } from '@/interfaces/Mode';

@Options({
  components: {
  },
  data () {
    return {
      sharedState: store.state,
    }
  },
  computed: {
    selectedLanguageEN() {
      return store.state.selectedLanguage === Language.EN;
    },
    selectedModeLIGHT() { return store.state.selectedMode === Mode.LIGHT; },
    getProfileData(): Profile {
      return this.sharedState.currentProfile as Profile;
    }
  },
  methods: {
    setSelectedMode(mode: any) {
      store.setSelectedMode(Mode[mode] as unknown as Mode);
    },
  }
})

export default class PortfolioHeader extends Vue {
}
