<template>
  <footer 
    :class="{'footer-background': true, 'portfolio-footer': isPortfolio, 'p-footer-light': isPortfolio && selectedModeLIGHT, 'p-footer-dark': isPortfolio && !selectedModeLIGHT, 'zerio-footer': !isPortfolio}"
    >
    <nav id="footer">
      <div v-if="!isPortfolio" class="line-between-langs">
        <button id="en_blog"
          :class="{'selected': selectedLanguageEN, 'lang-button-portfolio': true, 'lang-button-light': true}" 
          @click="setSelectedLanguage('EN')"
        >
          en
        </button>
        |
        <button id="de_blog"
          :class="{'selected': !selectedLanguageEN, 'lang-button-portfolio': true, 'lang-button-light': true}" 
          @click="setSelectedLanguage('DE')"
        >
          de
        </button>
      </div>
      <router-link 
        :class="{'footer-link': true, 'p-footer-link': isPortfolio, 'z-footer-link': !isPortfolio}" 
        to="/about"
        >
          Portfolio
      </router-link>
      <router-link 
        :class="{'footer-link': true, 'p-footer-link': isPortfolio, 'z-footer-link': !isPortfolio}" 
        to="/zerio"
        >
          Blog
      </router-link>
      <router-link v-if="isPortfolio"
        :class="{'footer-link': true, 'p-footer-link': isPortfolio, 'z-footer-link': !isPortfolio}" 
        to="/about"
        v-scroll-to="{ el: '#imprint', offset: -120, onDone: onDoneImprint }"
        >
          {{ selectedLanguageEN ? imprintTitleEN : imprintTitleDE }}
      </router-link>
      <router-link v-if="!isPortfolio"
        :class="{'footer-link': true, 'p-footer-link': isPortfolio, 'z-footer-link': !isPortfolio}" 
        to="/about"
        @click="setFocusToImprint()"
        >
          {{ selectedLanguageEN ? imprintTitleEN : imprintTitleDE }}
      </router-link>
      <router-link v-if="isPortfolio" 
        :class="{'footer-link': true, 'p-footer-link': isPortfolio, 'z-footer-link': !isPortfolio}" 
        to="/about"
        v-scroll-to="{ el: '#contact', offset: -120, onDone: onDoneContact }"
        >
          {{ selectedLanguageEN ? contactsTitleEN : contactsTitleDE }}
      </router-link>
      <router-link v-if="!isPortfolio"
        :class="{'footer-link': true, 'p-footer-link': isPortfolio, 'z-footer-link': !isPortfolio}" 
        to="/about"
        @click="setFocusToContact()"
        >
          {{ selectedLanguageEN ? contactsTitleEN : contactsTitleDE }}
      </router-link>
      <div class="display-at-md">
        <button :class="{'lang-button': true}" @click="setSelectedMode('LIGHT')">
          <i :class="{'fa': true, 'fa-sun-o': true, 'selected': selectedModeLIGHT, 'icon-light': !selectedModeLIGHT, 'icon-dark': selectedModeLIGHT}" aria-hidden="true"></i>
        </button>
        |
        <button :class="{'lang-button': true}" @click="setSelectedMode('DARK')">
          <i :class="{'fa': true, 'fa-moon-o': true, 'selected': !selectedModeLIGHT, 'icon-light': !selectedModeLIGHT, 'icon-dark': selectedModeLIGHT}" aria-hidden="true"></i>
        </button>
      </div>
      <div v-if="isPortfolio">
        <button 
          :class="{'selected': selectedLanguageEN, 'lang-button': true}" 
          @click="setSelectedLanguage('EN')"
        >
          en
        </button>
        |
        <button 
          :class="{'selected': !selectedLanguageEN, 'lang-button': true}" 
          @click="setSelectedLanguage('DE')"
        >
          de
        </button>
      </div>
      <router-link v-if="!isPortfolio" to="/">
          <img src="@/assets/z.svg" id="logo-footer" />
      </router-link>
    </nav>
  </footer>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import { Language } from '@/interfaces/Language';
import store from '@/store';
import { Mode } from '@/interfaces/Mode';

@Options({
    props: {
        isPortfolio: Boolean,
    },
    computed: {
      selectedLanguageEN() { return store.state.selectedLanguage === Language.EN;  },
      selectedModeLIGHT() { return store.state.selectedMode === Mode.LIGHT; },
      contactsTitleEN() { return store.state.currentSettingsForProfile.contactstitle?.en; },
      contactsTitleDE() { return store.state.currentSettingsForProfile.contactstitle?.de; },
      imprintTitleEN() { return store.state.currentSettingsForProfile.gdprtitle?.en; },
      imprintTitleDE() { return store.state.currentSettingsForProfile.gdprtitle?.de; },
    },
    methods: {
      setSelectedLanguage(lang){
        store.setSelectedLanguage(Language[lang]);
      },
      setSelectedMode(mode) {
        store.setSelectedMode(Mode[mode]);
      },
    }
})

export default class Portfoliofooter extends Vue {
  onDoneContact() {
    store.setFocusOnContact(true);
  }

  setFocusToContact() {
    store.setLinkFromOtherView(true);
    store.setFocusOnContact(true);
  }

  onDoneImprint() {
    store.setFocusOnImprint(true);
  }

  setFocusToImprint() {
    store.setLinkFromOtherView(true);
    store.setFocusOnImprint(true);
  }
}
</script>

<style lang="scss">
@import '@/main.scss';

    .fa.fa-moon-o.selected {
      font-weight: bold;
    }

    .fa.fa-moon-o {
      font-weight: 400;
    }

    .fa.fa-sun-o.selected {
      font-weight: bold;
    }

    .fa.fa-sun-o {
      font-weight: 400;
    }

    .footer-background {
        width: 100%;
        position: fixed;
        z-index: 1000;
        bottom: 0;
        left: 0;
    }

    .portfolio-footer {
        background: var(--background-light);
    }

    .zerio-footer {
        background: var(--background-dark);
    }

    #footer {
        margin: 2% 5% 5% 5%;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        font-size: 12px;
    }

    #logo-footer {
        height: 80px;
    }

    .footer-link {
        text-decoration: none;
    }

    .p-footer-link {
        color: var(--text-color);
    }

    .z-footer-link {
        color: var(--text-color-light);
    }

    .blog-footer-link {
      color: var(--text-color-light);
    }

    .line-between-langs {
      color: var(--text-color);
    }

    .lang-button {
      border: 0;
      padding: 0;
      background: none;
      color: var(--text-color);
    }

    .lang-button-portfolio {
      border: 0;
      padding: 0;
      background: none;
      color: var(--text-color);
    }

    .lang-button-light {
      color: var(--text-color-light);
    }

    .selected {
      font-weight: bold;
    }

    @media screen and (min-width: $break-md) {

      footer {
        max-height: 80px;
      }

      .portfolio-footer {
        background: unset;
        top: 0;
        bottom: unset;
        width: 85%;
        height: 50px;
        margin-left: 150px;
        margin-right: 50px;
        overflow: hidden;
      }

      .p-footer-link {
        color: unset;
      }

      .blog-footer-link {
        color: var(--text-color-light);
      }

      .lang-button {
        color: unset;
      }

      .p-footer-light.p.footer-link {
        color: var(--text-color);
      }

      .p-footer-dark.p-footer-link {
        color: var(--text-color-light);
      }

      .p-footer-light.lang-button {
        color: var(--text-color);
      }

      .p-footer-dark.lang-button {
        color: var(--text-color-light);
      }

      .selected.p-footer-dark.lang-button {
        color: var(--highlight-color);
      }

      .fa.fa-sun-o.icon-light {
        color: var(--text-color-light);
      }

      .fa.fa-sun-o.icon-dark {
        color: var(--text-color);
      }

      .fa.fa-moon-o.icon-light.selected {
        color: var(--highlight-color);
      }

      .fa.fa-sun-o.icon-dark {
        color: var(--text-color);
      }

      .p-footer-dark {
        background: var(--background-dark);
        color: var(--text-color-light);
      }

      #footer {
        margin-top: 20px;
        margin: 1% 5%;
      }

      #logo-footer {
        height: 50px;
      }
    }
</style>