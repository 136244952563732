<template>
  <div id="hardskills">
      <Sectionheader :title="selectedLanguageEN ? titleEN : titleDE" :supertitle="selectedLanguageEN ? cvTitleEN : cvTitleDE" :isH3="true" />
      <div id="skill-wrapper">
        <Skill 
          v-for="hardskill in getProfileData.hardskills"
          v-bind:key="hardskill.skillid"
          :imgSrc="hardskill.icon.url"
          :skillId="hardskill.id"
        />
      </div>
      <div id="skill-info-wrapper">
        <div 
          v-for="hardskill in getProfileData.hardskills"
          v-bind:key="hardskill.skillid"
          >
            <Skillinfo 
            v-if="getSelectedSkill == hardskill.id || screenWidthHigherThan950"
            :imgSrc="hardskill.icon.url"
            :title="hardskill.title"
            :skillid="hardskill.id"
            />
        </div>
      </div>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Sectionheader from '@/components/elements/Sectionheader.vue';
import Skill from '@/components/elements/Skill.vue';
import Skillinfo from '@/components/elements/Skillinfo.vue';
import { Language } from '@/interfaces/Language';
import store from '@/store';

@Options({
  components: {
    Sectionheader,
    Skill,
    Skillinfo
  },
  data () {
    return {
      sharedState: store.state,
    }
  },
  computed: {
    getProfileData(){
      return this.sharedState.currentProfile;
    },
    getSelectedSkill(){
      return this.sharedState.showInfoOfSkill;
    },
    screenWidthHigherThan950() {
      return screen.width > 950;
    },
    selectedLanguageEN() { return store.state.selectedLanguage === Language.EN;  },
    titleEN() { return store.state.currentSettingsForProfile.hardskillstitle?.en; },
    titleDE() { return store.state.currentSettingsForProfile.hardskillstitle?.de; },
    cvTitleEN() { return store.state.currentSettingsForProfile.cvtitle?.en; },
    cvTitleDE() { return store.state.currentSettingsForProfile.cvtitle?.de; },
  }
})
export default class Hardskills extends Vue {}
</script>

<style lang="scss">
@import '@/main.scss';

  #skill-wrapper {
    margin: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  #hardskills {
    position: relative;
  }

  #skill-info-wrapper {
    display: unset;
  }

  @media screen and (min-width: $break-sm) {
    
    #skill-wrapper {
      margin: 5% 25% 0 25%;
    }
  }

  @media screen and (min-width: $break-md) {

    #hardskills {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    #skill-wrapper {
      display: none;
    }

    #skill-info-wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: center;
    }
  }

  @media screen and (min-width: $break-lg) {
    #skill-info-wrapper {
      margin-left: 20%;
      margin-right: 20%;
    }
  }
</style>