
import { Options, Vue } from 'vue-class-component';
import PortfolioHeader from '@/components/semantic/PortfolioHeader.vue';
import Footer from '@/components/semantic/Footer.vue';
import Overview from '@/components/semantic/Overview.vue';
import Cv from '@/components/semantic/Cv.vue';
import Projects from '@/components/semantic/Projects.vue';
import Contact from '@/components/semantic/Contact.vue';
import Imprint from '@/components/semantic/Imprint.vue';
import * as http from '@/http';
import store from '@/store';
import * as settings from '@/utils/settings';
import { Mode } from '@/interfaces/Mode';

@Options({
  components: {
    PortfolioHeader,
    Overview,
    Cv,
    Projects,
    Contact,
    Imprint,
    Footer
  },
  data () {
    return {
      profile: {},
      sharedState: store.state,
    }
  },
  created: function () {
    this.getProfileInfo();
    this.getActiveSettingsProfile();
  },
  mounted: function () {
    this.checkIfFocusIsSetWaitAndUnset();
  },
  computed: {
    positioncv() { return store.state.currentSettingsForProfile.positioncv },
    positionprojects() { return store.state.currentSettingsForProfile.positionprojects},
    positionimprint() { return store.state.currentSettingsForProfile.positiongdpr },
    positioncontact() { return store.state.currentSettingsForProfile.positioncontact },
    cvIsFirst() { return settings.elementIsFirst(this.positioncv); },
    cvIsSecond() { return settings.elementIsSecond(this.positioncv); },
    cvIsThird() { return settings.elementIsThird(this.positioncv); },
    cvIsLast() { return settings.elementIsLast(this.positioncv); },
    projectsIsFirst() { return settings.elementIsFirst(this.positionprojects); },
    projectsIsSecond() { return settings.elementIsSecond(this.positionprojects); },
    projectsIsThird() { return settings.elementIsThird(this.positionprojects); },
    projectsIsLast() { return settings.elementIsLast(this.positionprojects); },
    contactIsFirst() { return settings.elementIsFirst(this.positioncontact); },
    contactIsSecond() { return settings.elementIsSecond(this.positioncontact); },
    contactIsThird() { return settings.elementIsThird(this.positioncontact); },
    contactIsLast() { return settings.elementIsLast(this.positioncontact); },
    imprintIsFirst() { return settings.elementIsFirst(this.positionimprint); },
    imprintIsSecond() { return settings.elementIsSecond(this.positionimprint); },
    imprintIsThird() { return settings.elementIsThird(this.positionimprint); },
    imprintIsLast() { return settings.elementIsLast(this.positionimprint); },
    selectedModeLIGHT() { return store.state.selectedMode === Mode.LIGHT; },
  },
  methods: {
    async getProfileInfo() {
      const profile = await http.getProfileById();
      store.setCurrentProfile(profile);
    },
    async getActiveSettingsProfile() {
      const settingsProfile = await http.getSettingsForProfileSite();
      store.setCurrentSettingsForProfile(settingsProfile);
      const about = this.$refs.about;
      settings.setColorStyles(about, store.state.currentSettingsForProfile);
    },
    checkIfFocusIsSetWaitAndUnset() {
      const delay = 1000;

      if(this.sharedState.focusOnImprint){
        
        if(this.sharedState.linkFromOtherView){
          this.waitAndScrollToImprint(delay);
          store.setLinkFromOtherView(false);
        }
        
        window.setTimeout(this.unsetFocus, delay + 700);
        
      } else if (this.sharedState.focusOnContact) {
        
        if(this.sharedState.linkFromOtherView){
          this.waitAndScrollToContact(delay);
          store.setLinkFromOtherView(false);
        }

        window.setTimeout(this.unsetFocus, delay + 700);
      } else {
        window.setTimeout(this.checkIfFocusIsSetWaitAndUnset, 1000);
      }
    },
    unsetFocus(){
      store.setFocusOnContact(false);
      store.setFocusOnImprint(false);
      window.setTimeout(this.checkIfFocusIsSetWaitAndUnset, 1000);
    }
    
  }
})
export default class About extends Vue {
  waitAndScrollToContact(delay: number){
    window.setTimeout(this.scrollToContact, delay);    
  }

  scrollToContact() {
    const contactComp: any = this.$refs.contact;
    contactComp.$el.scrollIntoView();
  }

  waitAndScrollToImprint(delay: number) {
    window.setTimeout(this.scrollToImprint, delay);
  }

  scrollToImprint() {
    const imprintComp: any = this.$refs.imprint;
    imprintComp.$el.scrollIntoView();
  }
}
