<template>
  <section id="projects">
      <Sectionheader @click="toggleExpansion()" :title="selectedLanguageEN ? projectsTitleEN : projectsTitleDE" :isExpanded="isExpanded" :isH3="false" :isNotCv="true" />
      <div v-if="selectedModeLIGHT" :class="{'expand': isExpanded, 'shrinked': !isExpanded, 'projects-list': true, 'background-light': selectedModeLIGHT, 'background-dark': !selectedModeLIGHT }">
          <template v-for="post in sharedState.currentProfile.posts" 
            v-bind:key="post.postid">
            <router-link to="/article" @click="setArticleId(post.id)">
              <Postoverview
                :thumbnailSrc="post.thumbnail.url"
                :title="post.title"
                :title_DE="post.title_DE"
                :teaser="post.teaser"
                :teaser_DE="post.teaser_DE"
                :colorStyle="getColorNumber(post.id)"
                :creatorname="post.author.name"
                :publishedAt="post.published_at"
              />
            </router-link>
          </template>
        </div>
        <div v-if="!selectedModeLIGHT" :class="{'expand': isExpanded, 'shrinked': !isExpanded, 'projects-list': true, 'background-light': selectedModeLIGHT, 'background-dark': !selectedModeLIGHT }">
          <template v-for="post in sharedState.currentProfile.posts" 
            v-bind:key="post.postid">
            <router-link to="/article" @click="setArticleId(post.id)">
              <Postoverview
                :thumbnailSrc="post.thumbnail.url"
                :title="post.title"
                :title_DE="post.title_DE"
                :teaser="post.teaser"
                :teaser_DE="post.teaser_DE"
                :colorStyle="getColorNumberDark(post.id)"
                :creatorname="post.author.name"
                :publishedAt="post.published_at"
              />
            </router-link>
          </template>
        </div>
  </section>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Sectionheader from '@/components/elements/Sectionheader.vue';
import Postoverview from '@/components/elements/Postoverview.vue';
import { Language } from '@/interfaces/Language';
import { Mode } from '@/interfaces/Mode';
import store from '@/store';

@Options({
  data () {
    return {
      isExpanded: false,
      sharedState: store.state,
      clicked: false,
    }
  },
  components: {
      Sectionheader,
      Postoverview
  },
  computed: {
    selectedLanguageEN() { return store.state.selectedLanguage === Language.EN;  },
    selectedModeLIGHT() { return store.state.selectedMode === Mode.LIGHT; },
    projectsTitleEN() { return store.state.currentSettingsForProfile.projectstitle?.en; },
    projectsTitleDE() { return store.state.currentSettingsForProfile.projectstitle?.de; },
  },
  methods: {
  }
})
export default class Projects extends Vue {
    toggleExpansion() {
      this.isExpanded = !this.isExpanded;
    }

    getColorNumber(id) {
      id = id - 1;
      return id % 2
    }

    getColorNumberDark(id) {
      return (id % 2) + 1;
    }

    setArticleId(articleId) {
      store.setArticleId(articleId);
    }
}
</script>

<style lang="scss" scoped>
@import '@/main.scss';

  #projects {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: $break-md) {
    .shrinked {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    .expand {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    .background-light {
      background: var(--background-light);
    }

    .background-dark {
      background: var(--background-dark);
    }
  }

  @media screen and (min-width: $break-lg) {
    .shrinked {
      
    }
  }
</style>