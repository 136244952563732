<template>
 <div id="tech-background">
  <header id="techblog-header">
      <div v-if="back" id="header-left">
        <router-link to="/zerio">
            <img id="back-button" src="@/assets/icons8-back-50.png" />  
        </router-link>
      </div>
      <div v-else id="header-left-empty"></div>
      <div id="header-center">
        <h1 class="zerio-title">ZERIO</h1>
        <h5 class="zerio-title">Development(); :Engineering: Design.</h5>
      </div>
      <div id="header-right"></div>
  </header>
 </div>
</template>

<script>
export default {
    props: {
        back: { default: false },
    },
    setup(props) {
        return { props }
    },
}
</script>

<style lang="scss">
@import '@/main.scss';

    #tech-background {
        width: 100%;
        background: var(--background-dark);
        position: fixed;
        z-index: 1;
    }

    #techblog-header {
        padding: 3% 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow: none;
    }

    #header-middle {
        display: flex;
        flex-direction: column;
    }

    #header-right {
        margin-right: 10%;
    }

    #header-left-empty {
        margin-left: 10%;
    }

    .zerio-title {
        color: var(--text-color-light);
    }

    #back-button {
        margin-top: 5%;
    }

    @media screen and (min-width: $break-md) {
        #techblog-header {
            padding: 20px 0 5px 0;
        }
    }
</style>