<template>
  <div :class="{'timeline-element': true, 'timeline-element-wt-ed': isOnWholeTimeline && isEducation, 'timeline-element-wt-pe': isOnWholeTimeline && !isEducation }">
    <div :class="{'dot': true, 'dot-wt-ed': isOnWholeTimeline && isEducation, 'dot-wt-pe': isOnWholeTimeline && !isEducation }"></div>
    <div :class="{'entry-box': true, 'entry-box-wt-ed': isOnWholeTimeline && isEducation, 'entry-box-wt-pe': isOnWholeTimeline && !isEducation}" @click="clickedOnElement(entryId)" >
        <p v-if="toDate != null && toDate !== '' && selectedLanguageEN">{{fromDateMonthlyEn}} - {{toDateMonthlyEn}}</p>
        <p v-if="toDate != null && toDate !== '' && !selectedLanguageEN">{{fromDateMonthlyDe}} - {{toDateMonthlyDe}}</p>
        <p v-if="fromDate === toDate && selectedLanguageEN">{{fromDateMonthlyEn}}</p>
        <p v-if="fromDate === toDate && !selectedLanguageEN">{{fromDateMonthlyDe}}</p>
        <p v-if="(toDate == null || toDate === '') && selectedLanguageEN">{{fromDateMonthlyEn}} - now</p>
        <p v-if="(toDate == null || toDate === '') && !selectedLanguageEN">{{fromDateMonthlyDe}} - heute</p>
        <p class="institution">{{institution}}</p>
        <p v-if="!selectedLanguageEN && description_DE" class="description">{{description_DE}}</p>
        <p v-else class="description">{{description}}</p>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import { Language } from '@/interfaces/Language';
import store from '@/store';

@Options({
    props: {
        entryId: '',
        fromDate: '',
        toDate: '',
        institution: '',
        description: '',
        description_DE: '',
        isOnWholeTimeline: false,
        isEducation: false,
    },
    computed: {
        fromDateMonthlyEn() {
            if(this.fromDate)
                return this.getMonthAndYearFromDateEn(this.fromDate);
            else
                return this.fromDate;
        },
        fromDateMonthlyDe() {
            if(this.fromDate)
                return this.getMonthAndYearFromDateDe(this.fromDate);
            else
                return this.fromDate;
        },
        toDateMonthlyEn() {
            if(this.toDate)
                return this.getMonthAndYearFromDateEn(this.toDate);
            else
                return this.toDate;
        },
        toDateMonthlyDe() {
            if(this.toDate)
                return this.getMonthAndYearFromDateDe(this.toDate);
            else
                return this.toDate;
        },
        selectedLanguageEN() { return store.state.selectedLanguage === Language.EN;  },
    },
    methods: {
        getMonthAndYearFromDateEn(val){
            const monthNames = ["January","February","March","April","May","June","July","August","September","October","November","December"];

            const date = new Date(val);
            const month = date.getMonth();
            const year = date.getFullYear();
            return monthNames[month] + " " + year;
        },
        getMonthAndYearFromDateDe(val){
            const monthNames = ["Jänner", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

            const date = new Date(val);
            const month = date.getMonth();
            const year = date.getFullYear();
            return monthNames[month] + " " + year;
        }
    }
})
export default class Timelineentry extends Vue {
    clickedOnElement(id){
        store.setShowInfoOfEntry(id);
    }
}
</script>

<style lang="scss">
@import '@/main.scss';

    p {
        color: var(--text-color);
        margin-bottom: -3.5%;
    }

    .entry-box {
        margin-bottom: 40px;
        text-align: left;
        margin-right: 10px;
    }

    .institution {
        font-weight: bold;
    }

    .description {
        font-style: italic;
        font-weight: Thin;
        font-size: 16px;
    }
    
    .dot {
        margin-left: -4.5%;
        margin-right: 0;
        margin-bottom: -8%;
        border-radius: 100%;
        background-color: var(--background-dark);
        width: 8px;
        height: 8px;
    }

    @media screen and (min-width: $break-sm) {
        p {
            margin-bottom: -10px;
        }

        .dot {
            margin-left: -4.25%;
            width: 12px;
            height: 12px;
        }

        .entry-box {
            margin-top: 42.5px;
        }
    }

    @media screen and (min-width: $break-md) {
        
        .dot {
            margin: 0;
        }

        .dot-wt-ed {
            background-color: var(--background-light);
            position: absolute;
            right: -25px;
            top: 5px;
        }

        .dot-wt-pe {
            margin-left: -25px;
            margin-bottom: -60px;
        }

        .timeline-element-wt-ed {
            position: relative;
            margin-left: -420px;
            max-width: 400px;
        }

        .timeline-element-wt-pe {
            margin-left: 20px;
        }

        .entry-box-wt-ed {
            p {
                color: var(--text-color-light);
                text-align: right;
            }
            
        }
    }
</style>