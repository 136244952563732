<template>
    <svg class="display-at-md" height="40" width="2030" v-if="left && !isNotCv" :style="{ fill: getHighlightColor }" >
        <rect y="12.5" width="2000" height="15" />
        <polygon points="2000,0 2030,20 2000,40" />
    </svg>
    <svg class="display-at-md" height="40" width="2030" v-if="left && isNotCv" :style="{ fill: getMediumColor }" >
        <rect y="12.5" width="2000" height="15" />
        <polygon points="2000,0 2030,20 2000,40" />
    </svg>
    <svg class="display-at-md" height="40" width="2000" v-if="right && !darkMode" :style="{ fill: getDarkColor }" >
        <rect x="30" y="12.5" width="2000" height="15" />
        <polygon points="30,0 0,20 30,40" />
    </svg>
    <svg class="display-at-md" height="40" width="2000" v-if="right && darkMode" :style="{ fill: getLightColor }" >
        <rect x="30" y="12.5" width="2000" height="15" />
        <polygon points="30,0 0,20 30,40" />
    </svg>
    <svg height="40" width="1030" v-if="!right && !left" :style="{ fill: getHighlightColor }" >
        <rect y="12.5" width="1000" height="15" />
        <polygon points="1000,0 1020,20 1000,40" />
    </svg>
</template>

<script>
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        left: false,
        right: false,
        darkMode: false,
        isNotCv: false,
        highlightcolor: "",
        mediumcolor: "",
        darkcolor: "",
        lightcolor: "",
    },
    computed: {
        getHighlightColor() { return this.highlightcolor; },
        getMediumColor() { return this.mediumcolor; },
        getDarkColor() { return this.darkcolor; },
        getLightColor() { return this.lightcolor; },
    }
})
export default class Arrow extends Vue {}
</script>
<style lang="scss">
@import '@/main.scss';
</style>