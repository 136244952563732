<template>
  <section id="imprint">
      <Sectionheader @click="toggleExpansion()" :title="selectedLanguageEN ? titleEN : titleDE" :isExpanded="isExpanded" :isOnFocus="isOnFocus" :isH3="false" :isNotCv="true" />
      <div :class="{'imprint-wrapper': true, 'expand': isExpanded, 'shrinked': !isExpanded}">
        <div v-if="selectedLanguageEN" :class="{'imprint-container': true, 'ic-light': selectedModeLIGHT, 'ic-dark': !selectedModeLIGHT}" v-html="getEnglishImprint">
        </div>
        <div v-else :class="{'imprint-container': true, 'ic-light': selectedModeLIGHT, 'ic-dark': !selectedModeLIGHT}" v-html="getGermanImprint">
        </div>
      </div>
  </section>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Sectionheader from '@/components/elements/Sectionheader.vue';
import store from '@/store';
import { Language } from '@/interfaces/Language';
import { Mode } from '@/interfaces/Mode';
import * as http from '@/http';

@Options({
  data () {
    return {
      isExpanded: false,
      imprint: {},
    }
  },
  components: {
      Sectionheader,
  },
  mounted: function() {
    this.getNewestImprint();
  },
  computed: {
    selectedLanguageEN() { return store.state.selectedLanguage === Language.EN;  },
    selectedModeLIGHT() { return store.state.selectedMode === Mode.LIGHT; },
    titleEN() { return store.state.currentSettingsForProfile.gdprtitle?.en; },
    titleDE() { return store.state.currentSettingsForProfile.gdprtitle?.de; },
    getEnglishImprint() {
      return this.imprint.text_EN;
    },
    getGermanImprint() {
      return this.imprint.text_DE;
    }
  },
  methods: {
    async getNewestImprint() {
      this.imprint = await http.getNewestImprint();
    }
  },
  props: {
    isOnFocus: false,
  }
})
export default class Imprint extends Vue {
    toggleExpansion() {
      this.isExpanded = !this.isExpanded;
    }
}
</script>

<style lang="scss" scoped>
@import '@/main.scss';

  .imprint-wrapper {
    text-align: left;
    overflow-x: hidden;

    .imprint-container {
      margin: 0 5% 10% 5%;
      hyphens: auto;
      overflow: hidden;
    }

    /deep/ h1 {
      margin-top: 40px;
    }

    /deep/ h2 {
      margin-top: 20px;
    }

    /deep/ h3 {
      margin-top: 40px;
    }

    /deep/ a {
      width: 80%;
    }
  }

  @media screen and (min-width: $break-md) {
    .imprint-wrapper {
      margin: 0 80px;
      margin-left: calc(50% - 360px);
      display: flex;
      justify-content: center;
      text-align: left;
      max-width: 85ch;

      .imprint-container {
        margin-bottom: 0;
        padding-bottom: 20px;
      }
    }



    .ic-dark {
      color: var(--text-color-light);

      /deep/ h1 {
        color: var(--text-color-light);
      }

      /deep/ h2 {
        color: var(--text-color-light);
      }

      /deep/ h3 {
        color: var(--text-color-light);
      }

      /deep/ h4 {
        color: var(--text-color-light);
      }

      /deep/ h5 {
        color: var(--text-color-light);
      }

      /deep/ p {
        color: var(--text-color-light);
      }

      /deep/ a {
        color: var(--text-color-light);
      }

      /deep/ li {
        color: var(--text-color-light);
      }

      /deep/ strong {
        color: var(--text-color-light);
      }

      /deep/ img {
        background: var(--background-light);
      }
    }
  }

  
</style>