<template>
  <div id="whole-timeline">
    <Sectionheader :isH3="true" :title="selectedLanguageEN ? titleEN : titleDE" :supertitle="selectedLanguageEN ? cvTitleEN : cvTitleDE" />
    <div class="timeline">
      <div class="left-box-timeline">
        <h3 v-if="selectedLanguageEN">{{eduTitleEN}}</h3>
        <h3 v-else>{{eduTitleDE}}</h3>
      </div>
      <div class="right-box-timeline">
        <h3 v-if="selectedLanguageEN">{{profTitleEN}}</h3>
        <h3 v-if="!selectedLanguageEN">{{profTitleDE}}</h3>
          <div v-for="entry in sharedState.currentProfile.allTimelineEntries" 
              v-bind:key="entry.id"
          >
            <Timelineentry 
                :entryId="entry.id"
                :fromDate="entry.fromdate"
                :toDate="entry.todate"
                :institution="entry.institute"
                :description="entry.description"
                :description_DE="entry.description_DE"
                :isOnWholeTimeline="true"
                :isEducation="entry.education"
            />
            <Additionalinfo 
              v-if="getSelectedEntry === entry.id || screenWidthHigherThan950"
              :textDE="entry.detail_DE"
              :textEN="entry.detail_EN"
              :imageSrc="entry.thumbnail.url"
              :isEducation="entry.education"
            />
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Sectionheader from '@/components/elements/Sectionheader.vue';
import Timelineentry from '@/components/elements/Timelineentry.vue';
import Additionalinfo from '@/components/elements/Additionalinfo.vue';
import { Language } from '@/interfaces/Language';
import store from '@/store';

@Options({
  data () {
      return {
          sharedState: store.state,
          isActive: false,
      }
  },
  components: {
      Sectionheader,
      Timelineentry,
      Additionalinfo
  },
  computed: {
    selectedLanguageEN() { return store.state.selectedLanguage === Language.EN;  },
    titleEN() { return store.state.currentSettingsForProfile.wholetimelinetitle?.en; },
    titleDE() { return store.state.currentSettingsForProfile.wholetimelinetitle?.de; },
    cvTitleEN() { return store.state.currentSettingsForProfile.cvtitle?.en; },
    cvTitleDE() { return store.state.currentSettingsForProfile.cvtitle?.de; },
    eduTitleEN() { return store.state.currentSettingsForProfile.educationtitle?.en; },
    eduTitleDE() { return store.state.currentSettingsForProfile.educationtitle?.de; },
    profTitleEN() { return store.state.currentSettingsForProfile.professionstitle?.en; },
    profTitleDE() { return store.state.currentSettingsForProfile.professionstitle?.de; },
    getSelectedEntry(){
      return store.state.showInfoOfEntry;
    },
    screenWidthHigherThan950() {
      return screen.width > 950;
    },
  }
})
export default class Wholetimeline extends Vue {}
</script>

<style lang="scss" scoped>
@import '@/main.scss';

#whole-timeline {
  display: none;
}

@media screen and (min-width: $break-md) {
  #whole-timeline {
    display: unset;
  }

  .timeline {
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 0;
    margin: 0;
  }

  .left-box-timeline {
    width: 50%;
    background: var(--background-dark);
    padding-top: 30px;

    h3 {
      color: var(--text-color-light);
      margin-bottom: 40px;
    }
  }

  .right-box-timeline {
    width: 50%;
    background: var(--background-light);
    padding-top: 30px;

    h3 {
      margin-bottom: 40px;
    }
  }
}
</style>